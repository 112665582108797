import { template as template_140a9857aef541f4b33f9ca17246e4ad } from "@ember/template-compiler";
const FKControlMenuContainer = template_140a9857aef541f4b33f9ca17246e4ad(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
