import { template as template_824fea16db0d4c1e9ff4a03bdc0334bb } from "@ember/template-compiler";
const SidebarSectionMessage = template_824fea16db0d4c1e9ff4a03bdc0334bb(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
