import { template as template_e5d0b2cb56be40e2804d0c79de6f08ec } from "@ember/template-compiler";
const FKText = template_e5d0b2cb56be40e2804d0c79de6f08ec(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
