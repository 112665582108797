import { template as template_647a2e42c4b340518231534ec641da63 } from "@ember/template-compiler";
const FKLabel = template_647a2e42c4b340518231534ec641da63(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
